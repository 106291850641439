body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffeee3 !important;
}

h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 22px;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

fieldset {
  border-radius: 10px !important;
}

.projectPlotWrapper::-webkit-scrollbar {
  width: 2px;
}

.projectPlotWrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(52, 137, 203, 0.6);
  border-radius: 10px;
}

.projectPlotWrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(52, 137, 203, 0.6);
}

body::-webkit-scrollbar {
  width: 3px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(52, 137, 203, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.scrollbar {
  border-radius: 20px;
  background-color: #eb5c00 !important;
}
/*
.scrollarea-content {
  margin-right: 15px !important;
}*/

.rbc-date-cell {
  text-align: center !important;
  font-size: 14px !important;
  color: #666 !important;
}

.rbc-calendar {
  border: 1px solid #ccc !important;
  border-radius: 20px !important;
  padding: 15px !important;
}

.rbc-header {
  border-bottom: 0 !important;
}

.rbc-event {
  border-radius: 10px !important;
}

.rbc-event-continues-after {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rbc-event-continues-prior {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rbc-event-content {
  font-size: 14px !important;
}

.content-test {
  width: 1200px;
}

.left-menu-item span,
.left-menu-item svg,
.left-menu-item-closed span,
.left-menu-item-closed svg {
  color: #363636;
}

.left-menu-item:hover,
.left-menu-item-closed:hover {
  background-color: rgb(255, 244, 237) !important;
  border-right: 3px solid #eb5c00;
}

.left-menu-item:hover,
.left-menu-item:hover svg,
.left-menu-item:hover span,
.left-menu-item-closed:hover,
.left-menu-item-closed:hover svg,
.left-menu-item-closed:hover span {
  color: #eb5c00;
}

.left-menu-item-cont {
  padding: 0 !important;
}

.left-menu-item-cont a {
  display: block;
  width: 100%;
}

.left-menu-item,
.left-menu-item-closed {
  padding: 9px 16px !important;
}

.is-active svg,
.is-active span {
  color: #eb5c00;
}

.is-active .left-menu-item {
  border-right: 3px solid #eb5c00;
  background-color: rgb(255, 244, 237);
}

.is-active .left-menu-item-closed {
  border-left: 3px solid #eb5c00;
}

/*button:hover {
  background-color: #22985d !important;
  color: #fff !important;
}*/

/*a:hover {
  color: #22985d !important;
}*/

fieldset {
  border-radius: 5px !important;
}

.table-header-wrapper {
  box-shadow: -8px 13px 8px -14px rgba(216, 216, 216, 0.75);
}

.table-header-wrapper th {
  padding: 10px 18px 5px;
  line-height: 20px;
}

.custom-table-body:before {
  content: "-";
  display: block;
  line-height: 1em;
  color: transparent;
}

.sales-filter-wrapper fieldset {
  border-radius: 0px !important;
}

.menu-list-filter-wrapper fieldset {
  border-radius: 0px !important;
  border-left: 0;
}

.menu-list-filter-wrapper input {
  padding: 15px;
}

.sales-filter-wrapper input {
  padding: 15px;
}

.menu-list-filter-wrapper label {
  transform: translate(14px, 17px) scale(1);
}

.sales-filter-wrapper label {
  transform: translate(14px, 17px) scale(1);
}

.menu-list-filter-wrapper div:hover fieldset {
  /* border-color: #ccc !important; */
}

.pagination {
  list-style: none;
  text-align: right;
}

.pagination li {
  display: inline-block;
  background-color: #fff;
  color: #eb5c00;
  padding: 0;
  border: 1px solid #f5f5f5;
  cursor: pointer;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
}

.pagination li:hover {
  background: #eb5c00;
  color: #fff;
}

.pagination .disabled,
.pagination .disabled:hover {
  background-color: #f1f0f0;
  color: #a5a5a5;
  cursor: default;
}

.pagination .previous {
  border-radius: 3px;
}

.pagination .active {
  background-color: #eb5c00;
  color: #fff;
}

.pagination a {
  padding: 8px 13px;
  display: block;
  font-size: 14px;
}

.pagination a:focus {
  outline: none;
}

#deleteClosedDate:hover {
  background-color: #f50057 !important;
}

#validation-error {
  color: #f50057;
}

#validation-error2 {
  color: #f44336;
  margin-top: 5px;
  margin-bottom: 8px;
}

#map {
  height: 100%;
}

.toast-width {
  width: 500px;
}

.text-center {
  text-align: center;
}

@media print {
  #printPageButton {
    display: none;
  }
}

.app-header {
  border-top: 8px solid #3489cb;
  background-color: #fff;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: 0px 8px 6px 0px #e0e0e0;
}

.loggin-header{
  padding-bottom: 40px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


