.app-header {
  border-top: 8px solid #3489cb;
  background-color: #fff;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: 0px 8px 6px 0px #e0e0e0;
}

.loggin-header{
  padding-bottom: 40px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

